div.relance-container {
    margin: 50px 75px;
    padding: 20px 0;
    border-top: 5px solid #f1f1f1;
    border-top-width: 5px;
    border-top-style: solid;
    border-top-color: rgb(241, 241, 241);
}
div.relance-title p {
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
    color: #13829b;
    font-size: 20px;
}
nav.relance-menu {
    display: flex;
    justify-content: center;
}

nav.relance-menu ul {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

nav.relance-menu ul li {
    color: #000;
}


@media screen and (max-width: 900px){
    div.relance-container {
        margin: 20px 10px;
    }
    nav.relance-menu ul {
        width: 100%;
    }
}

@media screen and (max-width: 600px){
    nav.relance-menu ul {
        flex-direction: column;
        min-height: 100px;
    }
}