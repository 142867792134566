.slider-img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 185px;
  z-index: -1;
}

.slider {
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.slides {
  width: 500%;
  height: 400px;
  display: flex;
}

.slides input {
  display: none;
}

.slide {
  width: 20%;
  transition: 2s;
}

.slide img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.slide.third img {
  background-position: 50% 10%;
}


.navigation-manual {
  position: absolute;
  width: 100%;
  margin-top: -40px;
  display: flex;
  justify-content: center;
}

.manual-btn {
  border: 2px solid #40D3DC;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
}

.manual-btn:not(:last-child) {
  margin-right: 40px;
}

.manual-btn:hover {
  background: #40D3DC;
}

#radio1:checked~.first {
  margin-left: 0;
}

#radio2:checked~.first {
  margin-left: -20%;
}

#radio3:checked~.first {
  margin-left: -40%;
}



.navigation-auto {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 360px;
}

.navigation-auto div {
  border: 2px solid #40D3DC;
  padding: 5px;
  border-radius: 10px;
  transition: 1s;
}

.navigation-auto div:not(:last-child) {
  margin-right: 40px;
}

#radio1:checked~.navigation-auto .auto-btn1 {
  background: #40D3DC;
}

#radio2:checked~.navigation-auto .auto-btn2 {
  background: #40D3DC;
}

#radio3:checked~.navigation-auto .auto-btn3 {
  background: #40D3DC;
}

@media screen and (max-width: 1000px){
  .slider-img{
    top: 94px;
  }
}
@media screen and (max-width: 600px){
  .slider-img{
    top: 160px;
  }
}