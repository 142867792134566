div.contact-tel {
    background-color: #13829b;
    color: #fff;
    margin: 50px 0;
}
div.contact-tel div.contact-container {
    width: auto;
    padding: 30px;
    letter-spacing: 0.1px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
div.contact-tel p {
  text-align: center;
  font-size: 22px;
  margin-top: 10px;
}
div.contact-tel strong {
    font-size: 26px;
}
div.contact-tel a {
    color: #fff;
    font-weight: 700;
    font-size: 22px;
}

@media screen and (max-width: 1000px){
    div.contact-tel p {
      font-size: 20px;
    }
    div.contact-tel strong {
        font-size: 24px;
    }
    div.contact-tel a {
        font-size: 20px;
    }
}