.home-title {
    font-size: 35px;
    margin: 100px 0 40px;
    text-align: center;
}
.container-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto 0;
}
section.actualites div.img {
    max-width: 300px;
}
section.actualites img {
    width: 100%;
}

.input-group {
    width: 100%;
    display: flex;
    justify-content: center;  
    margin: 20px 0;  
}