div.title h4 {
    font-weight: normal;
    font-size: 1.2rem;
    margin-top: 10px;
}
section.home {
    margin-top: 586px;
}
div.content {
    display: flex;
    justify-content: center;
}
div.content div.centrage {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
div.content div.col-right {
    width: 300px;
}
div.content div.col-right img {
    width: 100%;
}

div.content div.col-left {
    width: 68%;
}

section.home div.image {
    display: flex;
    justify-content: center;
}
section.home div.image img {
    max-width: 300px;
    max-height: 500px;
}
section.home .double-colonne.second {
    margin-bottom: 50px;
}
