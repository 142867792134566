.card {
  max-width: 1100px;
  min-width: 1100px;
  padding: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  margin: 10px;
  min-height: 200px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  transition: all 0.3s ease;
}
.card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.card h2 {
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: #005db1;
}
.card .content {
    width: 80%;
    padding: 0 0 0 25px;
}
.card .content p {
  color: #a9a9a9;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 0.8em;
}
.card div.link {
  display: flex;
  align-items: flex-end;
  text-align: end;
  width: 20%;
  min-width: 185px;
  max-width: 185px;
}
.card div.link a {
  display: inline-block;
  padding: 0 20px;
  vertical-align: middle;
  color: #0c0c0c;
  width: 90%;
  border: 1px solid #0c0c0c;
  line-height: 39px;
  text-align: center;
  font-size: 14px;
}

@media screen and (max-width: 1500px){
  .card {
    max-width: 320px;
    min-width: 320px;
    flex-direction: column;
  }
}
