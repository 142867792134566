strong {
  font-weight: bold;
}
span.citation{
  font-style: italic;
  color: #13829b;
}

section.page {
  display: flex;
  justify-content: center;
}

div.container {
  width: 95%;
}

div.double-colonne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: justify;
}

div.text {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #6f6f6f;
  letter-spacing: 0.1px;
  padding: 10px 0 0 30px;
}

div.text h2 {
  text-align: start;
  font-size: 2rem;
}

div.text p {
  width: 95%;
  line-height: 25px;
  font-size: 1.1rem;
  padding-top: 20px;
}

div.text.second {
  padding-left: 50px;
}

div.image {
  width: 40%;
  overflow: hidden;
}

div.first.image {
  margin: 10px 30px 20px 0;
}

div.second.image {
  margin: 10px 0 0 10px;
}

div.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 450px;
}

@media screen and (max-width: 1500px) {
  div.text {
    width: 70%;
  }

  div.double-colonne {
    min-height: 400px;
    overflow: hidden;
  }
}

@media screen and (max-width: 1300px) {
  div.double-colonne {
    flex-direction: column;
    justify-content: space-between;
  }
  div.text {
    width: 100%;
  }
  div.text.second {
    padding-left: 15px;
  }
  div.image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 50px 0 20px 0px !important;
  }
  div.image img {
    width: 700px;
  }
}

@media screen and (max-width: 800px) {
  div.container {
    width: 100%;
  }
  div.double-colonne {
    margin-top: 50px;
  }
  div.text {
    min-height: 500px;
    max-height: 1120px;
    padding: 0 15px;
  }
  div.text h2 {
    font-size: 1.7rem;
    text-align: center;
  }
  div.text p {
    width: 100%;
  }
  div.image img {
    width: 300px;
  }
}


