.article-section {
  padding: 100px 50px;
}

.article-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.article-content {
  width: 70%;
  margin: 0 30px 10px 0px;
}

.article-section h1 {
  text-align: center;
  color: #000;
}

h2.article-Title {
  margin: 0 0 10px;
  font-size: 24px;
  line-height: 28px;
}

p.article-Content {
  text-align: justify;
  line-height: 22px;
  margin: 0 30px 10px 0px;
}
.article-section .links {
  display: flex;
  flex-wrap: wrap;
}

.article-section .date {
  color: #a9a9a9;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 0.8em;
}

.article-section div.image {
  display: flex;
  align-items: center;
  object-fit: cover;
  max-height: 420px;
}
.article-section a:last-child {
  margin-left: 50px;
}
.article-section a {
  display: flex;
  align-self: center;
}


@media screen and (max-width: 1100px) {
  .article-container {
    flex-direction: column;
  }
  .article-content {
    width: 100%;
  }
  .article-section a:last-child {
    margin: 20px 5px
  }
}