.tel {
    font-size: 20px;
    cursor: initial;
    font-family: 'Open Sans', sans-serif;
    text-align: end;
}
footer a {
    color: #fff;
}

footer {
    background-color: #13829b;
    width: 100%;
    color: #fff;
}
div.footer-container {
    padding: 20px 40px;
    min-height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
div.footer-logo {
    display: flex;
    align-items: center;
}
div.footer-logo a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 30px;
}
span.name-logo {
    font-size: 16px;
    font-weight: 700;   
}
span.baseline {
    font-size: 14px;
}
div.footer-infos {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    line-height: 30px;
    font-size: 14px;
}

@media screen and (max-width: 700px) {
    div.footer-container {
        padding: 20px 50px;
        flex-direction: column;
        justify-content: space-between;
    }
    div.footer-logo {
        justify-content: center;
        text-align: center;
    }
    div.footer-infos {
        margin-top: 20px;
    }
}