header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 170px;
    min-height: 5vh;
    z-index: 8;
    transition: 0.6s;
    background-color: #13829b;
    color: #fff;
}

.adress-header {
    font-size: 0.9rem;
    color: #fff;
}

header #toggle {
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

header #toggle::before {
    content: '';
    position: absolute;
    top: 4px;
    width: 100%;
    height: 2px;
    background: #fff;
}

header #toggle::after {
    content: '';
    position: absolute;
    bottom: 4px;
    width: 100%;
    height: 2px;
    background: #fff;
}

.banner {
    position: relative;
    width: 100%;
}

.banner.active header #toggle {
    position: fixed;
    right: 30px;
}

.banner.active header #toggle::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #fff;
    transition: all 0.5s;
}

.banner.active header #toggle::after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background: #fff;
    transition: all 0.5s;
}

#navigation {
    position: fixed;
    top: 0px;
    right: -400px;
    width: 400px;
    height: 100vh;
    background: #13829b;
    z-index: 7;
    display: grid;
    place-items: center;
    transition: 0.5s;
}

#navigation.active {
    right: 0;
}

#navigation ul {
    position: relative;
    display: flex;
    flex-direction: column;
}

#navigation ul li:not(:last-child) {
    margin-bottom: 30px;
}

#navigation ul li a {
    display: inline-block;
    font-size: 1.5em;
    font-weight: 600;
    text-transform: capitalize;
    transition: 0.5s;
    color: #fff;
}

#navigation ul li a:hover {
    color: #db9500
}


div.nav-info {
    position: fixed;
    top: 0vh;
    padding: 72px 50px 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    z-index: 4;
}

div.nav-info-centrage {
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

div.nav-info-logo {
    color: #3a3b3f;
    display: flex;
    flex-direction: column;
}
div.nav-info-logo h4 {
    text-transform: uppercase;
    transition: all 0.3s;
}
div.nav-info-logo h4:hover{
    color: #6F6F64;
}

div.nav-info-logo p {
    padding-top: 5px;
    font-size: 0.9rem;
}

div.nav-info-contact {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

div.nav-info-contact ul {
    min-height: 55px;
    min-width: 550px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


div.nav-info-contact ul a.tel {
    color: #db9500;
    font-size: 1.4rem;
    font-weight: 700;
    position: relative;
    top: 8.5px;
}
div.nav-info-contact ul a.contact-btn {
    padding: 15px 25px;
    position: relative;
    top: 8.5px;
    color: #fff;
    font-size: 0.8rem;
    transition: all 0.3s ease;
}

div.nav-info-contact ul a.doctolib {
    background-color: #13829b;
    color: #fff;
    padding: 15px 25px;
    font-size: 0.8rem;
}

div.nav-info-contact ul span {
    display: inline-block;
    width: 65px;
}

div.nav-info-contact ul span img {
    max-width: 100%;
    position: relative;
    top: 5px;
    left: 5px;
}


nav.menu {
    background-color: #efeeee;
    height: 6vh;
    position: fixed;
    top: 20vh;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 6;
}

nav.menu ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 60%;
}
nav.menu ul li {
    color: #000;
}
nav.menu ul li a {
    padding: 12.7px 25px;
    transition: all 0.3s;
}
nav.menu ul li a:hover {
    background-color: #db9500;
    color: #efeeee;
}
.activeLink {
    background-color: #db9500;
    color: #efeeee;
}


@media screen and (max-width: 1500px) {
    nav.menu {
        height: 7vh;
        top: 22vh;
    }
    div.nav-info-centrage {
        width: 95%;
    }
    div.nav-info-contact {
        width: 65%;
    }
}
@media screen and (max-width: 1300px) {

    nav.menu ul {
        width: 75%;
    }

}
@media screen and (max-width: 1000px) {
    div.nav-info {
        display: none;
    }
    nav.menu {
        top: 5vh;
    }
    nav.menu ul {
        width: 80%;
    }

    nav.menu ul {
        width: 90%;
        font-size: 0.9rem;
    }

}
@media screen and (max-width: 840px) {
    header {
        padding: 0px 30px;
    }
    #navigation {
        width: 250px;
    }
    #navigation ul li a {
        margin: 10px 0;
    }
    nav.menu ul {
        width: 100%;
        font-size: 0.8rem;
    }
    nav.menu ul li {
        padding: 10px 0;
    }
}




@media screen and (max-width: 600px) {
    .adress-header {
        display: none;
    }
    header {
        justify-content: end;
    }
    nav.menu {
        min-height: 160px;
    }
    nav.menu ul {
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 1.1rem;
    }
}