.info-container {
    display: flex;
    justify-content: center;
    width: 100%;
    color: #fff;
    height: auto;
    margin: 50px 0;
}
.info-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    padding: 50px;
    background-color: #13829b;
}
.info-content div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.info-content ul{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    list-style: none;
}
.info-content ul li {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.info-content img {
    width: 35px;
}
.info-mutuelle p {
    font-size: 16px;
    max-width: 300px;
    background-color: white;
    padding: 20px 30px;
    font-weight: 900;
    color: #13829b;
    border: 1px solid #fff;
    text-align: center;
}
  
@media screen and (max-width: 1100px){
    .info-content {
        flex-direction: column;
        align-items: center;
    }
    .info-container div {
        margin: 30px 0;
    }
}