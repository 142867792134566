*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #000;
}
p {
  text-align: justify;
}
ul {
  list-style-type: none;
}
html, body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
}
section {
  margin-top: 28vh;
}

.lid-btn {
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #db9500;
  transition: ease-out 0.4s;
  padding: 12px 25px;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: capitalize;
  background-color: #db9500;
  border: 1px solid #db9500;
  max-width: 170px;
  color: #fff;
}
.lid-btn:hover {
  box-shadow: inset 300px 0 0 0 #13829b;
  border: 1px solid #13829b;
}


h1, h2, h3 {
  font-family: 'Josefin Sans';
}
h3 {
  font-weight: 400;
}
div.title {
  text-align: center;
  padding: 40px;
}
div.title h1 {
  font-size: 35px;
  font-weight: 600;
  color: #39374d;
}
div.title h3 {
  margin-top: 10px;
}
div.intro-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
}
.title-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.sub-title {
  width: 100%;
  padding-top: 25px;
  display: flex;
  justify-content: center;
}
div.title p {
  font-style: italic;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  color: #6f6f6f;
  width: 100%;
}




@media screen and (max-width: 1000px) {
  section {
    margin-top: 12vh;
  }
}


@media screen and (max-width: 600px) {
  section {
    margin-top: 26vh;
  }
  div.title {
    padding: 0;
  }
  div.title h1 {
    font-size: 27px;
  }
  div.title p {
    font-size: 14px;
  }
}