/*______________________ GALLERY div ______________________*/

div.gallery {
    min-height: 60vh;
    padding: 20px 0 50px;
}

div.gallery div.gallery-container {
    display: flex;
    width: 100%;
    height: 80%;
}

div.gallery div.container {
    width: 100%;
    max-width: 120rem;
    margin: auto;
}

div.gallery div.gallery {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: .2rem;
    grid-template-areas:
        'img-1 img-2 img-3 img-3 img-4 img-5'
        'img-6 img-7 img-3 img-3 img-8 img-9';
}

div.gallery div.gallery a {
    width: 100%;
    height: 15rem;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

div.gallery div.gallery a p {
    font-size: 2rem;
    position: relative;
    color: rgba(255, 255, 255, .7);
    z-index: 100;
    opacity: 0;
    transition: opacity .5s;
}

div.gallery div.gallery a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    opacity: 0;
    transition: opacity .5s;
}

div.gallery div.gallery a:hover p,
div.gallery div.gallery a:hover::before {
    opacity: 1;
}

div.gallery div.gallery a.img-1 {
    grid-area: img-1;
    background-image: url('../../assets/statue-ange-sans-tete.jpg');
    background-size: cover;
}

div.gallery div.gallery a.img-2 {
    grid-area: img-2;
    background-image: url('../../assets/statue-ange.jpg');
    background-size: cover;
}

div.gallery div.gallery a.img-3 {
    grid-area: img-3;
    min-height: 30.2rem;
  
    background-image: url('../../assets/tag-joker-psychology.jpg');
    background-size: cover;
}

div.gallery div.gallery a.img-4 {
    grid-area: img-4;
    background-image: url('../../assets/peinture-encre.jpg');
    background-size: cover;
}

div.gallery div.gallery a.img-5 {
    grid-area: img-5;
    background-image: url('../../assets/structure-artistique.jpg');
    background-size: cover;
}

div.gallery div.gallery a.img-6 {
    grid-area: img-6;
    background-image: url('../../assets/peinture-artistique.jpg');
    background-size: cover;
}

div.gallery div.gallery a.img-7 {
    grid-area: img-7;
    background-image: url('../../assets/homme-musée.jpg');
    background-size: cover;
}

div.gallery div.gallery a.img-8 {
    grid-area: img-8;
    background-image: url('../../assets/peinture-homme-femme.jpg');
    background-position: 100% 0%;
    background-size: cover;
}

div.gallery div.gallery a.img-9 {
    grid-area: img-9;
    background-image: url('../../assets/peinture-fille-fleurs.jpg');
    background-size: cover;
}


@media screen and (max-width: 800px) {
    div.gallery div.gallery {
        grid-template-areas:
            'img-1 img-1 img-1 img-1'
            'img-2 img-2 img-2 img-2'
            'img-3 img-3 img-3 img-3'
            'img-4 img-4 img-4 img-4'
            'img-5 img-5 img-5 img-5'
            'img-6 img-6 img-6 img-6'
            'img-7 img-7 img-7 img-7'
            'img-8 img-8 img-8 img-8'
            'img-9 img-9 img-9 img-9';

    }
    div.gallery div.gallery a {
        width: 150%;
    }
    div.gallery div.gallery a.img-5{
        background-position: 100% 30%;
    }
}