div.fullpage {
    margin: 30vh 0 50px 0;
}

div.contact {
    display: flex;
    justify-content: center;
}

div.contact div.boxs {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    min-height: 700px;
    overflow: hidden;
}

div.contact div.boxs div.left {
    width: 70%;
    display: flex;
    flex-direction: column;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    overflow: hidden;
}

div.contact  div.left div.info {
    height: 50%;
    border-bottom: 1px solid #dbdbdb;
    padding: 25px;
}

div.contact  div.left div.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
}

div.contact  div.left div.info p {
    font-weight: 500;
    color: #4d4d4d;
}

div.contact  div.left div.info a p {
    text-decoration: underline;
    color: #019267;
}

div.contact  div.left div.map {
    height: 50%;
    width: 100%;
}

div.contact  div.left div.map iframe {
    width: 100%;
    height: 100%;
}



@media screen and (max-width: 1000px){
    div.fullpage {
        margin-top: 50px;
    }
}
